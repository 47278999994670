@font-face {
  font-family: 'General Sans';
  src: url('assets/fonts/GeneralSans-Light.woff2') format('woff2'),
    url('assets/fonts/GeneralSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'General Sans';
  src: url('assets/fonts/GeneralSans-Regular.woff2') format('woff2'),
    url('assets/fonts/GeneralSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'General Sans';
  src: url('assets/fonts/GeneralSans-Medium.woff2') format('woff2'),
    url('assets/fonts/GeneralSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'General Sans';
  src: url('assets/fonts/GeneralSans-Semibold.woff2') format('woff2'),
    url('assets/fonts/GeneralSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'General Sans';
  src: url('assets/fonts/GeneralSans-Bold.woff2') format('woff2'),
    url('assets/fonts/GeneralSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('assets/fonts/CabinetGrotesk-Medium.woff') format('woff2'),
    url('assets/fonts/CabinetGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('assets/fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
    url('assets/fonts/CabinetGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('assets/fonts/CabinetGrotesk-Extrabold.woff2') format('woff2'),
    url('assets/fonts/CabinetGrotesk-Extrabold.woff') format('woff');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('assets/fonts/CabinetGrotesk-Black.woff2') format('woff2'),
    url('assets/fonts/CabinetGrotesk-Black.woff') format('woff');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
